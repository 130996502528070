import { useAppConfig } from '@rugby-au/app-config';
import { useState } from 'react';

export const useFetch = ({}) => {
  const { setNotification, setIsLogged } = useAppConfig();
  const [isFetching, setIsFetching] = useState<boolean | number>(true);
  const [fetchError, setFetchError] = useState<{ message?: string; status?: number }>({});

  const fetchRequest = async (functionName: (params?: any) => Promise<any>, params?: any) => {
    try {
      setIsFetching(true);
      const response = await functionName(params);
      // console.log('🚀 ~ file: useFetch.ts:13 ~ fetchRequest ~ response:', functionName.toString(), JSON.stringify(response));
      if (response?.error) {
        console.log('🚀 ~ file: useFetch.ts:15 ~ fetchRequest ~ response?.error:', functionName.toString(), JSON.stringify(response?.error));
        setFetchError({ message: response.error?.message, status: response.error?.status });
        //setNotification({ severity: 'error', message: response.error?.message ?? 'Something went wrong, please try again later or contact support.' });
        if ([401, 403].includes(response.error?.status)) {
          setIsLogged(false);
        }
      }
      setIsFetching(false);
      return response;
    } catch (error: any) {
      console.error('🚀 ~ file: useFetch.ts:21 ~ fetchRequest ~ error', functionName.toString(), error);
      setIsFetching(false);
      setFetchError({ message: error.message, status: error.status });
      setNotification({ severity: 'error', message: 'Something went wrong, please try again later or contact support.' });
    }
  };

  return { isFetching, fetchRequest, fetchError };
};
